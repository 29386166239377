import { useNavigate } from 'react-router-dom';
import { useFetchResource } from '../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../api_endpoints';
import { humanReadableDateString } from '../components/human_readable_date';

interface DashboardUser {
  id: number;
  name: string;
  actionString?: string;
}

type DashboardCategories = Record<string, DashboardUser[]>;

export default function CoachDashboardPage() {
  const [, , dashboardCategories] = useFetchResource<DashboardCategories>(
    CoachingApiEndpoints.dashboardUsers,
  );

  return (
    <div className="py-4">
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-10">
          <UsersWithUpcomingAppointments
            dashboardCategories={dashboardCategories}
          />

          <DashboardUserList
            title="Users with unread messages"
            users={dashboardCategories?.unread_messages || []}
            customRecordSection="messages"
          />

          <DashboardUserList
            title="Users with unpublished notes"
            users={dashboardCategories?.unpublished_notes || []}
            customRecordSection="appointments"
          />

          <DashboardUserList
            title="Users with Pending Actions"
            users={dashboardCategories?.users_with_pending_actions || []}
          />

          <DashboardUserList
            title="All Users"
            users={dashboardCategories?.all_users || []}
          />
        </div>
      </main>
    </div>
  );
}

interface CustomCategorySectionProps {
  dashboardCategories?: DashboardCategories;
}
function UsersWithUpcomingAppointments({
  dashboardCategories,
}: CustomCategorySectionProps) {
  const users = dashboardCategories?.upcoming_sessions;

  if (!users) {
    return null;
  }

  const displayData: DashboardUser[] =
    users.map((u: any) => ({
      id: u.id,
      name: u.name,
      actionString:
        'Scheduled for: ' + humanReadableDateString(u.scheduled_for),
    })) || [];

  return (
    <DashboardUserList
      title="Users with upcoming appointments"
      users={displayData}
      customRecordSection="appointments"
    />
  );
}

interface DashboardUserListProps {
  users: DashboardUser[];
  title: string;
  customRecordSection?: string;
}
function DashboardUserList({
  users,
  title,
  customRecordSection,
}: DashboardUserListProps) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="text-xl font-bold">{title}</div>
      <div className="mt-2 px-4 sm:px-0">
        <div className="bg-white shadow overflow-hidden rounded-md">
          {users.length > 0 ? (
            <ul className="divide-y divide-gray-200">
              {users.map((record) => (
                <li
                  key={record.id}
                  className="px-6 py-4 flex justify-between cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/coaching/user/${record.id}/${
                        customRecordSection || ''
                      }`,
                    );
                  }}>
                  <div className="cursor-pointer">
                    {record.id} - {record.name}
                  </div>
                  <div>{record.actionString}</div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="px-6 py-4">List is empty! 🎉</div>
          )}
        </div>
      </div>
    </div>
  );
}
