import { useState } from 'react';
import {
  updateResource,
  useFetchResource,
} from '../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../api_endpoints';
import CoachingModal from '../components/coaching_modal';
import { humanReadableDateString } from '../components/human_readable_date';
import { Field, Formik } from 'formik';

interface Referral {
  id: number;
  patient_first_name: string;
  patient_last_name: string;
  created_at: string;
  patient_phone_number: string;
  patient_email: string;
  reason_for_referral: string;
  referrer_name: string;
  referrer_contact_details: string;

  partner?: {
    id: number;
    name: string;
  };
}

export default function PatientReferralsPage() {
  const [, loading, referrals, refreshReferrals] = useFetchResource<Referral[]>(
    CoachingApiEndpoints.referrals,
  );

  const [selectedReferral, setSelectedReferral] = useState<Referral | null>(
    null,
  );

  const openModal = (referral: Referral) => {
    setSelectedReferral(referral);
  };

  const closeModal = () => {
    setSelectedReferral(null);
  };

  const updateReferral = async (values: any) => {
    const [status, updatedReferral] = await updateResource(
      CoachingApiEndpoints.referral(selectedReferral!.id),
      values,
    );

    if (status !== 200) {
      alert('Failed to update referral. Please try again');
      return;
    }

    await refreshReferrals();
    setSelectedReferral(updatedReferral);
  };

  return (
    <>
      <div className="py-4">
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-10">
            <div className="text-xl font-bold">Open Patient Referrals</div>

            {!loading && referrals !== undefined && (
              <div>
                <div className="bg-white shadow overflow-hidden rounded-md">
                  {referrals.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
                            Referral ID
                          </th>
                          <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/6">
                            Patient Name
                          </th>
                          <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/6">
                            Referred On
                          </th>
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/6">
                            Provider
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {referrals.map((referral) => (
                          <tr
                            key={referral.id}
                            className="hover:bg-gray-50 cursor-pointer"
                            onClick={() => openModal(referral)}>
                            <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {referral.id}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                              {`${referral.patient_first_name} ${referral.patient_last_name}`}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                              {humanReadableDateString(referral.created_at)}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {referral.referrer_name}
                              {referral.partner && (
                                <span className="block text-xs text-gray-400 mt-1">
                                  {referral.partner.name}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="px-6 py-4">List is empty! 🎉</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>

      {selectedReferral && (
        <CoachingModal onClose={closeModal} open={selectedReferral !== null}>
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Patient Details</h2>
            <ReferralField
              label="Referral ID:"
              value={`${selectedReferral.id}`}
            />
            <EditableReferralField
              label="Patient Name:"
              fieldName=""
              value={`${selectedReferral.patient_first_name} ${selectedReferral.patient_last_name}`}
              onSubmit={updateReferral}
            />
            <ReferralField
              label="Referred On:"
              value={humanReadableDateString(selectedReferral.created_at)}
            />
            <EditableReferralField
              label="Patient Phone Number:"
              fieldName="patient_phone_number"
              value={prettyPhoneNumber(selectedReferral.patient_phone_number)}
              onSubmit={updateReferral}
              allowCopy={true}
            />
            <EditableReferralField
              label="Patient Email:"
              fieldName="patient_email"
              value={selectedReferral.patient_email}
              onSubmit={updateReferral}
            />

            <div className="mt-4 border-t border-gray-300 pt-4 space-y-4">
              <h2 className="text-xl font-bold">Referral Notes</h2>
              <EditableReferralField
                label="Reason for Referral:"
                fieldName="reason_for_referral"
                value={selectedReferral.reason_for_referral}
                onSubmit={updateReferral}
                as="textarea"
              />
            </div>

            <div className="mt-4 border-t border-gray-300 pt-4 space-y-4">
              <h2 className="text-xl font-bold">Referring Provider</h2>
              <ReferralField
                label="Partner Details:"
                value={
                  selectedReferral.partner
                    ? `- ${selectedReferral.partner.name} (ID: ${selectedReferral.partner.id})`
                    : 'No partner assigned'
                }
              />
              <EditableReferralField
                label="Provider Name:"
                value={selectedReferral.referrer_name}
                fieldName="referrer_name"
                onSubmit={updateReferral}
              />
              <EditableReferralField
                label="Contact Details:"
                value={selectedReferral.referrer_contact_details}
                fieldName="referrer_contact_details"
                onSubmit={updateReferral}
              />
            </div>
          </div>
        </CoachingModal>
      )}
    </>
  );
}
export function prettyPhoneNumber(phoneNumber: string): string {
  const cleaned = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[2]})-${match[3]}-${match[4]}`;
  }

  return phoneNumber; // Return the original input if it doesn't match the expected format
}

function ReferralField(props: {
  label: string;
  value: string;
  allowCopy?: boolean;
}) {
  return (
    <div className="flex">
      <div className="">
        <span>{props.label}</span>
        <span className="ml-2">{props.value}</span>
      </div>
      <div className="ml-2">
        {props.allowCopy && <CopyButton textToCopy={props.value} />}
      </div>
    </div>
  );
}

function EditableReferralField(props: {
  onSubmit: (value: any) => Promise<void>;
  allowCopy?: boolean;
  value: string;
  label: string;
  fieldName: string;
  as?: string;
}) {
  const [showEdit, setShowEdit] = useState(false);
  const initialValues = {
    [props.fieldName]: props.value,
  };
  return showEdit ? (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await props.onSubmit(values);
        setShowEdit(false);
      }}>
      {({ handleSubmit, isSubmitting }) => (
        <form
          onSubmit={handleSubmit}
          className="flex items-center justify-between">
          {props.as === 'textarea' ? (
            <Field
              name={props.fieldName}
              as="textarea"
              className="focus:ring-indigo-500 border border-indigo-500 rounded-md sm:text-sm p-3 min-h-200px flex-grow"
            />
          ) : (
            <Field
              name={props.fieldName}
              as="input"
              className="focus:ring-indigo-500 border border-indigo-500 rounded-md sm:text-sm p-3 flex-grow"
            />
          )}
          <div className="space-x-2 ml-8">
            <button
              type="submit"
              className="bg-blue-500 text-white rounded px-3 py-2 hover:bg-blue-700 focus:outline-none"
              disabled={isSubmitting}>
              Save
            </button>
            <button
              type="button"
              className="text-red-500 hover:text-red-700 focus:outline-none ml-2"
              disabled={isSubmitting}
              onClick={() => {
                setShowEdit(false);
              }}>
              Cancel
            </button>
          </div>
        </form>
      )}
    </Formik>
  ) : (
    <div className="flex justify-between items-center">
      <ReferralField
        label={props.label}
        value={props.value}
        allowCopy={props.allowCopy}
      />
      <div>
        <button
          className="text-blue-500 hover:text-blue-700 focus:outline-none"
          onClick={() => {
            setShowEdit(true);
          }}>
          Edit
        </button>
      </div>
    </div>
  );
}

function CopyButton(props: { textToCopy: string }) {
  return (
    <button
      className="text-gray-500 hover:text-gray-700 focus:outline-none"
      onClick={() => {
        navigator.clipboard.writeText(props.textToCopy);
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-4 w-4">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
        />
      </svg>
    </button>
  );
}
