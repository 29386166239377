import { Formik } from 'formik';
import { CmsApiEndpoints } from '../../coaching/api_endpoints';
import FormButton from '../../coaching/components/forms/form_button';
import WaveSelectInput from '../../coaching/components/forms/wave_select_input';
import WaveStringField from '../../coaching/components/forms/wave_string_field';
import WaveTextField from '../../coaching/components/forms/wave_text_field';
import { ExistingByte } from '../../types/cms/byte';
import { postResource, updateResource } from '../../utils/api_request_helpers';

interface ByteFormProps {
  onSuccess: () => void;
  byte?: ExistingByte;
}
export default function ByteForm({ byte, ...props }: ByteFormProps) {
  const initialValues = {
    title: byte?.title || '',
    description: byte?.description || '',
    overview_illustration_name: byte?.overview_illustration_name || '',
    type: byte?.type || '',
    minutes_to_complete: byte?.minutes_to_complete || '',
    content_image_names: byte?.content_image_names?.join(',') || '',
    alt_text: byte?.alt_text?.join('\n') || '',
    unity_scene_name: byte?.unity_scene_name || '',
    unity_device_orientation: byte?.unity_device_orientation || '',
    hybrid_identifier: byte?.hybrid_identifier || '',
    associated_data_byte_id: byte?.associated_data_byte_id || '',
    audio_filename: byte?.audio_filename || '',
    video_filename: byte?.video_filename || '',
    cover_art_filename: byte?.cover_art_filename || '',
    quiz_metadata: JSON.stringify(byte?.quiz_metadata || ''),
    illustrated: byte?.illustrated || false,
  };

  const submitToServer = async (values: typeof initialValues) => {
    const cleanContentFileNames = values.content_image_names
      .split(',')
      .map((f) => f.trim());

    const cleanAltText = values.alt_text.split('\n').map((f) => f.trim());

    const apiPayload = Object.assign({}, values, {
      content_image_names: cleanContentFileNames,
      alt_text: cleanAltText,
    });

    let status;
    if (byte?.id) {
      [status] = await updateResource(
        CmsApiEndpoints.byte(byte.id),
        apiPayload,
      );
    } else {
      [status] = await postResource(CmsApiEndpoints.bytes, apiPayload);
    }

    if (status > 299) {
      alert(
        `Unable to ${
          byte?.id ? 'update' : 'create'
        } byte, please try again or contact engineering`,
      );
      return;
    }

    props.onSuccess();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitToServer}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <WaveStringField name="title" label="Title" required />
          <WaveTextField name="description" label="Description" required />
          <WaveStringField
            name="overview_illustration_name"
            label="Illustration Filename"
            required
          />
          <WaveStringField
            name="minutes_to_complete"
            label="Estimated Minutes to Complete"
            required
          />
          <WaveSelectInput
            name="type"
            label="Type of Byte"
            required
            options={[
              'SlideByte',
              'UnityByte',
              'AudioByte',
              'VideoByte',
              'HybridByte',
              'QuizByte',
            ]}
          />

          {values.type === 'SlideByte' && (
            <>
              <WaveTextField
                name="content_image_names"
                label="Comma separated list of file names"
                required
              />

              <WaveTextField
                name="alt_text"
                label="Alt Text for Images. 1 line per image with newline between"
                required
              />

              <WaveSelectInput
                name="illustrated"
                label="Illustrated?"
                options={['true', 'false']}
                required
              />
            </>
          )}

          {values.type === 'UnityByte' && (
            <>
              <WaveStringField
                name="unity_scene_name"
                label="Unity Scene Name"
                required
              />

              <WaveSelectInput
                name="unity_device_orientation"
                label="Unity Device Orientation"
                options={['portrait', 'landscape']}
                required
              />

              <WaveStringField
                name="associated_data_byte_id"
                label="Uses Data from other Unity Byte ID"
              />
            </>
          )}

          {values.type === 'HybridByte' && (
            <>
              <WaveStringField
                name="hybrid_identifier"
                label="Hybrid Identifier (no spaces please)"
                required
              />
            </>
          )}

          {values.type === 'AudioByte' && (
            <>
              <WaveStringField
                name="audio_filename"
                label="Audio Filename"
                required
              />

              <WaveStringField
                name="cover_art_filename"
                label="Cover Art Filename"
                required
              />
            </>
          )}

          {values.type === 'VideoByte' && (
            <>
              <WaveStringField
                name="video_filename"
                label="Video Filename"
                required
              />
            </>
          )}

          {values.type === 'QuizByte' && (
            <>
              <WaveTextField name="quiz_metadata" label="Quiz JSON" required />
            </>
          )}

          <FormButton />
        </form>
      )}
    </Formik>
  );
}
