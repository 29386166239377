import { Formik } from 'formik';
import FormButton from '../../coaching/components/forms/form_button';
import { postResource } from '../../utils/api_request_helpers';
import { InternalToolsApiEndpoints } from '../../coaching/api_endpoints';
import { useState } from 'react';
import WaveStringField from '../../coaching/components/forms/wave_string_field';

export default function PartnerAccessCodesPage() {
  const [codes, setCodes] = useState<string[]>([]);

  const generateCodes = async (values: any, formik: any) => {
    const [status, codes] = await postResource(
      InternalToolsApiEndpoints.partnerAccessCodes,
      values,
    );

    if (status !== 201) {
      alert('Unable to generate codes, please try again');
      return;
    }

    setCodes(codes);
    formik.resetForm();
  };

  return (
    <>
      <div className="mt-10 flex justify-between items-center px-10">
        <h1 className="text-2xl">Generate Partner Access Codes</h1>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg my-4 p-4 mr-10">
        <Formik
          initialValues={{ partner_id: '', number_of_codes: '10' }}
          onSubmit={generateCodes}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <WaveStringField
                  name="partner_id"
                  label="Partner ID"
                  required
                />
              </div>

              <div>
                <WaveStringField
                  name="number_of_codes"
                  label="Number of Codes"
                  required
                />
              </div>

              <FormButton title="Generate Codes" />
            </form>
          )}
        </Formik>

        {codes.length > 0 && (
          <div className="mt-6">
            <h2 className="text-lg font-medium text-gray-900">
              Generated Codes
            </h2>
            <div className="mt-2 p-3 bg-gray-50 rounded-md">
              {codes.map((code, index) => (
                <div key={index} className="font-mono">
                  {code}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
