import { Field, useFormikContext } from 'formik';
import { WaveFieldProps } from './wave_field_props';
import { useState } from 'react';

interface WaveTextFieldWithAISuggestionProps extends WaveFieldProps {
  aiSuggestion?: string;
}

export default function WaveTextFieldWithAiSuggestion(
  props: WaveTextFieldWithAISuggestionProps,
) {
  const [didInsertAiSuggestion, setDidInsertAiSuggestion] = useState(false);
  const { setFieldValue } = useFormikContext();

  const handleUseAISuggestion = () => {
    if (props.aiSuggestion) {
      setDidInsertAiSuggestion(true);
      setFieldValue(props.name, props.aiSuggestion);
    }
  };

  return (
    <div className="sm:col-span-4">
      <div className="space-y-2">
        {props.label && (
          <label
            htmlFor="user_facing_summary"
            className="block text-sm font-medium text-gray-700">
            {props.label}
          </label>
        )}
        {props.aiSuggestion && !didInsertAiSuggestion && (
          <button
            type="button"
            onClick={handleUseAISuggestion}
            className="text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline">
            Insert AI suggestion
          </button>
        )}
      </div>
      <div className="mt-1 rounded-md">
        <Field
          name={props.name}
          as="textarea"
          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 min-h-[200px]"
        />
      </div>
    </div>
  );
}
