import { Formik, Field, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import { postResource } from '../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../coaching/api_endpoints';
import { Byte } from '../../cms/pages/bytes_page';
import WaveStringField from '../../coaching/components/forms/wave_string_field';

export default function ByteSearchPage() {
  const initialValues = {
    query: '',
    semantic_bias: '0.5',
  };

  const [bytes, setBytes] = useState<Byte[]>();

  const submitToServer = async (
    values: { query: string },
    formik: FormikHelpers<any>,
  ) => {
    formik.setSubmitting(true);
    const [status, result] = await postResource(
      CoachingApiEndpoints.recommendableBytesSearch,
      values,
    );

    if (status !== 200) {
      alert('Unable to submit search, please try again');
      formik.setSubmitting(false);
      return;
    }

    setBytes(result);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Byte Search Testing</h1>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit, isSubmitting }) => (
          <Form className="space-y-4 mb-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="query"
                className="block text-sm font-medium text-gray-700">
                Search Query
              </label>
              <Field
                as="textarea"
                id="query"
                name="query"
                placeholder="Search for bytes..."
                className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 min-h-[50px] overflow-y-auto resize-none"
              />

              <WaveStringField
                name="semantic_bias"
                label="Semantic Bias (0-1)"
              />
            </div>
            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {bytes && (
        <div>
          <h2 className="text-xl font-bold mb-2">AI Response</h2>
          <div className="p-4 border rounded-md bg-gray-50 whitespace-pre-line">
            {bytes.map((byte) => (
              <div key={byte.id}>
                {byte.type} - {byte.id}: {byte.title}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
