import { useState } from 'react';
import { SessionNote } from '../../../../types/coaching/session_note';
import {
  fetchResource,
  postResource,
} from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingButton from '../../coaching_button';
import HumanReadableDate from '../../human_readable_date';
import LinkButton from '../../link_button';

interface PastNoteProps {
  note: SessionNote;
  sessionNumber?: number;
  isExpandedByDefault?: boolean;
  onEdit: (note: SessionNote) => void;
  onPublish: () => void;
}
export default function PastSessionNote({
  note,
  sessionNumber,
  ...props
}: PastNoteProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const publishNote = async () => {
    const [status] = await postResource(
      CoachingApiEndpoints.publish_user_session_note(note.id),
      {},
    );

    if (status !== 200) {
      alert('Unable to publish note. Please try again');
      return;
    }

    props.onPublish();
  };

  const prepNoteForEdit = async () => {
    const [status, enrichedNote] = await fetchResource(
      CoachingApiEndpoints.user_session_note(note.id),
    );

    if (status !== 200) {
      alert('Problem editing note, please try again');
      return;
    }

    props.onEdit(enrichedNote as SessionNote);
  };

  return (
    <>
      <div>
        <div className="text-lg border-b-2 border-b-gray-100 flex justify-between">
          <div className={note.published_on ? '' : 'text-gray-400'}>
            {!note.published_on && <span>DRAFT - </span>}
            {!!sessionNumber && <span>Session {sessionNumber} - </span>}
            <HumanReadableDate isoValue={note.created_at} />
          </div>
          {!note.published_on && (
            <div>
              <div className="flex space-x-4">
                <CoachingButton
                  title="Edit"
                  onClick={() => {
                    prepNoteForEdit();
                  }}
                />
                <CoachingButton title="Publish" onClick={publishNote} />
              </div>
            </div>
          )}
        </div>

        <LinkButton
          title={isExpanded ? 'Hide Details' : 'View Details'}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />

        {isExpanded && (
          <>
            <NoteSection
              title="User Facing Summary: "
              content={note.user_facing_summary}
            />

            <NoteSection
              title="Reviewed Coaching Client Relationship"
              content={note.reviewed_coach_client_relationship?.toString()}
              hideIfBlank
            />
            <NoteSection
              title="Reviewed EWB Quiz Results"
              content={note.reviewed_ewb_results?.toString()}
            />
            <NoteSection
              title="Reviewed DASS Quiz Results"
              content={note.reviewed_dass_results?.toString()}
            />

            <NoteSection
              title="Presenting Concern"
              content={note.presenting_concern}
              hideIfBlank
            />
            <NoteSection
              title="Past Work on Presenting Concern"
              content={note.past_work_on_presenting_concern}
              hideIfBlank
            />

            <NoteSection
              title="Focus Area Domain"
              content={note.focus_area_target_domain}
            />
            <NoteSection
              title="Focus Area Skill"
              content={note.focus_area_target_skill}
            />
            <NoteSection
              title="Focus Area Discussion"
              content={note.focus_area_discussion}
            />
            <NoteSection
              title="Focus Area Readiness to Change"
              content={note.focus_area_readiness_to_change}
            />

            <NoteSection
              title="Risk(s) Observerd"
              content={note.risk_observed?.join(',')}
            />
            <NoteSection title="Risk Note" content={note.risk_notes} />

            <NoteSection
              title="Progress Checkin"
              content={note.progress}
              hideIfBlank
            />

            <NoteSection
              title="Precieved Obstacles"
              content={note.percieved_obstacles}
              hideIfBlank
            />
            <NoteSection
              title="Motivators"
              content={note.motivators}
              hideIfBlank
            />
            <NoteSection
              title="Strengths"
              content={note.strengths}
              hideIfBlank
            />

            <NoteSection
              title="Today my client and I had good rapport"
              content={note.coach_rating_of_rapport?.today_rapport}
            />
            <NoteSection
              title="Today my client and I communicated well"
              content={note.coach_rating_of_rapport?.communicated_well}
            />
            <NoteSection
              title="Today my client and I are worked together on mutually agreed upon goals"
              content={note.coach_rating_of_rapport?.worked_together_on_goals}
            />

            <NoteSection title="Extra Notes" content={note.internal_note} />
          </>
        )}
      </div>
    </>
  );
}

function NoteSection(props: {
  title: string;
  content?: string;
  hideIfBlank?: boolean;
}) {
  if (props.hideIfBlank && !props.content) {
    return null;
  }

  return (
    <div className="my-3">
      <div className="font-semibold">{props.title}</div>
      <div>{props.content}</div>
    </div>
  );
}
