import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';
import LinkButton from './link_button';
import { logOutEmployee } from '../../authentication/login_page';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CoachingNavigation() {
  return (
    <Disclosure as="nav" className="bg-white border-b-1 border-black">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <div className="font-bold text-lg">Coaching Tool</div>
                </div>
                <div className="sm:ml-6 sm:flex sm:space-x-8">
                  <NavLink
                    to="dashboard"
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-indigo-500 border-b-2' : '',
                        'text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium',
                      )
                    }>
                    Dashboard
                  </NavLink>
                  <div className="border-transparent text-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                    Calendar
                  </div>

                  <NavLink
                    to="referrals"
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-indigo-500 border-b-2' : '',
                        'text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium',
                      )
                    }>
                    Referrals
                  </NavLink>

                  <NavLink
                    to="assignment"
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-indigo-500 border-b-2' : '',
                        'text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-medium',
                      )
                    }>
                    Assignment
                  </NavLink>
                </div>
              </div>
              <div className="sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <CogIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <LinkButton
                            title="Sign Out"
                            onClick={() => {
                              logOutEmployee();
                            }}
                          />
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
