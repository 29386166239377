import { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import { postResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import { RecommendableByte } from '../user_record/recommended_content';

interface ByteSearchFiedlProps {
  name: string;
  label?: string;
}

export default function ByteSearchField({ name, label }: ByteSearchFiedlProps) {
  const debounceMs = 300;
  const minSearchLength = 2;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<RecommendableByte[]>();
  const [selectedItem, setSelectedItem] = useState<RecommendableByte>();
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Debounced API call
  const searchAPI = async (term: string) => {
    if (term.length < minSearchLength) {
      setResults([]);
      return;
    }

    try {
      setIsLoading(true);
      const [status, bytes] = await postResource(
        CoachingApiEndpoints.recommendableBytesSearch,
        { query: term, semantic_bias: 0 },
      );
      if (status !== 200) throw new Error('Failed to fetch results');

      setResults(bytes);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle search input changes with debounce
  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      searchAPI(value);
    }, debounceMs);
  };

  // Handle item selection
  const handleSelectItem = (byte: RecommendableByte) => {
    setSelectedItem(byte);
    setFieldValue(name, byte.id);
    setIsOpen(false);
    setSearchTerm('');
  };

  // Handle keyboard navigation
  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isOpen || !results || results.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === results.length - 1 ? 0 : prevIndex + 1,
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? results.length - 1 : prevIndex - 1,
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < results.length) {
          handleSelectItem(results[highlightedIndex]);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full mb-4">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}

      <div ref={dropdownRef} className="relative">
        <div className="relative">
          <input
            type="text"
            id={`${name}-search`}
            className={`w-full px-3 py-2 bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              meta.touched && meta.error ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="Search..."
            value={searchTerm || (selectedItem ? selectedItem.title : '')}
            onChange={handleSearchChange}
            onFocus={() => setIsOpen(true)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
        </div>

        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md border border-gray-300 max-h-60 overflow-auto">
            {isLoading && (
              <div className="flex justify-center items-center p-4">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
              </div>
            )}

            {!isLoading && results && results.length > 0 && (
              <ul className="py-1">
                {results.map((byte, index) => (
                  <li
                    key={byte.id}
                    className={`px-4 py-2 hover:bg-blue-50 cursor-pointer text-sm ${
                      highlightedIndex === index ? 'bg-blue-100' : ''
                    }`}
                    onClick={() => handleSelectItem(byte)}>
                    {byte.title}
                  </li>
                ))}
              </ul>
            )}

            {!isLoading &&
              results?.length === 0 &&
              searchTerm.length >= minSearchLength && (
                <div className="p-4 text-sm text-gray-500 text-center">
                  No results found
                </div>
              )}

            {!isLoading &&
              results?.length === 0 &&
              searchTerm.length > 0 &&
              searchTerm.length < minSearchLength && (
                <div className="p-4 text-sm text-gray-500 text-center">
                  Type at least {minSearchLength} characters to search
                </div>
              )}
          </div>
        )}
      </div>

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-500">{meta.error}</div>
      ) : null}

      {/* Hidden input to store the actual value */}
      <input type="hidden" {...field} />
    </div>
  );
}
