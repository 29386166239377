import { Field, Formik } from 'formik';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import CoachingModal from '../coaching_modal';
import FormButton from '../forms/form_button';
import {
  AssignNewContentProps,
  RecommendableByte,
} from './recommended_content';
import ByteSearchField from '../forms/byte_search_field';
import { useState } from 'react';

export default function AssignNewContentForm(props: AssignNewContentProps) {
  const [showAllBytes, setShowAllBytes] = useState(false);
  const initialValues = {
    byte_id: '',
    is_homework: false,
  };

  const submitToServer = async (values: typeof initialValues) => {
    const [status] = await postResource(
      CoachingApiEndpoints.userAssignedContent(props.userId),
      values,
    );

    if (status !== 201) {
      alert(
        'Unable to complete request, if assigning homework, make sure there is a completed session note. If issue persists contact engineering',
      );
      return;
    }

    props.onSuccess();
    props.onClose();
  };

  return (
    <CoachingModal open={props.show} onClose={props.onClose}>
      <Formik initialValues={initialValues} onSubmit={submitToServer}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="space-y-8 min-h-[60vh]">
            <div className="">
              <label
                htmlFor="is_homework"
                className="block text-sm font-medium text-gray-700">
                Assign as Homework
              </label>
              <div className="mt-1 flex">
                <Field name="is_homework" type="checkbox" />
              </div>
            </div>

            <div className="mb-16">
              <ByteSearchField name="byte_id" label="Byte" />
            </div>

            <div>
              <button
                type="button"
                className="text-blue-500 hover:underline"
                onClick={() => {
                  setShowAllBytes(true);
                }}>
                I can't find the byte I'm looking for?
              </button>
            </div>

            {showAllBytes && (
              <div className="my-4">
                <AllBytesDropDown />
              </div>
            )}

            <div>
              <FormButton />
            </div>
          </form>
        )}
      </Formik>
    </CoachingModal>
  );
}

function AllBytesDropDown() {
  const [loadingBytes, , assignableBytes] = useFetchResource<
    RecommendableByte[]
  >(CoachingApiEndpoints.recommendableBytes);

  return (
    <div>
      <label
        htmlFor="byte_id"
        className="block text-sm font-medium text-gray-700">
        Byte
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <Field
          required
          name="byte_id"
          as="select"
          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300">
          {loadingBytes ? (
            <option value="">loading...</option>
          ) : (
            <>
              <option></option>
              {assignableBytes?.map((byte) => (
                <option key={byte.id} value={byte.id}>
                  {byte.title}
                </option>
              ))}
            </>
          )}
        </Field>
      </div>
    </div>
  );
}
