import { useState } from 'react';
import UserRecordSection from './user_record_section';
import { Formik, FormikHelpers } from 'formik';
import WaveStringField from '../forms/wave_string_field';
import FormButton from '../forms/form_button';
import { useParams } from 'react-router-dom';
import {
  postResource,
  useFetchResource,
} from '../../../utils/api_request_helpers';
import {
  CoachingApiEndpoints,
  InternalToolsApiEndpoints,
} from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import WaveTextField from '../forms/wave_text_field';

export default function CustomNotifications() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [notificationType, setNotificationType] = useState<string>();
  const [, , pastNotifications, refetchPastNotifications] = useFetchResource<
    PastNotification[]
  >(CoachingApiEndpoints.userOutreachMessages(userId));

  const renderNotificationForm = (notificationType: string | undefined) => {
    if (notificationType === 'push') {
      return (
        <PushNotificationForm
          userId={userId}
          onSuccess={() => {
            refetchPastNotifications();
            alert('Push notification sent successfully!');
          }}
        />
      );
    }
    if (notificationType === 'email') {
      return (
        <EmailNotificationForm
          userId={userId}
          onSuccess={() => {
            refetchPastNotifications();
            alert('Email notification sent successfully!');
          }}
        />
      );
    }

    if (notificationType === 'sms') {
      return (
        <SmsNotificationForm
          userId={userId}
          onSuccess={() => {
            refetchPastNotifications();
            alert('SMS notification sent successfully!');
          }}
        />
      );
    }
    return null;
  };

  return (
    <UserRecordSection title="Custom Notifications" loading={false}>
      <div>
        <div className="mt-4">
          <select
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md border-gray-300"
            onChange={(e) => {
              setNotificationType(e.target.value);
            }}>
            <option value="">Choose Notification Type</option>
            <option value="email">Email</option>
            <option value="push">Push Notification</option>
            <option value="sms">SMS</option>
          </select>
        </div>

        <div className="mt-4 mb-2">
          {renderNotificationForm(notificationType)}
        </div>
      </div>

      <div className="mt-6">
        <PastNotifications pastNotifications={pastNotifications} />
      </div>
    </UserRecordSection>
  );
}

interface NotificationFormProps {
  userId: string;
  onSuccess: () => void;
}

function PushNotificationForm(props: NotificationFormProps) {
  const initialValues = {
    title: '',
    body: '',
    deep_link_path: '',
    user_id: props.userId,
  };

  const submitPushNotification = async (
    values: typeof initialValues,
    formik: FormikHelpers<any>,
  ) => {
    formik.setSubmitting(true);

    const [status] = await postResource(
      InternalToolsApiEndpoints.customNotifications.push,
      values,
    );

    formik.setSubmitting(false);

    if (status !== 201) {
      alert(
        'Unable to send push notification. Please try again or contact engineering.',
      );
      return;
    }

    formik.resetForm();
    props.onSuccess();
  };

  return (
    <div className="my-2 px-4">
      <Formik initialValues={initialValues} onSubmit={submitPushNotification}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-2">
            <WaveStringField name="title" label="Title" />
            <WaveStringField name="body" label="Body" />
            <WaveStringField name="deep_link_path" label="Deep Link Path" />
            <FormButton title="Send Notification" disabled={isSubmitting} />
          </form>
        )}
      </Formik>
    </div>
  );
}

function SmsNotificationForm(props: NotificationFormProps) {
  const initialValues = {
    content: '',
    user_id: props.userId,
  };

  const submitToApi = async (
    values: typeof initialValues,
    formik: FormikHelpers<any>,
  ) => {
    formik.setSubmitting(true);

    const [status] = await postResource(
      InternalToolsApiEndpoints.customNotifications.sms,
      values,
    );

    formik.setSubmitting(false);

    if (status !== 201) {
      alert('Unable to send. Please try again or contact engineering.');
      return;
    }

    formik.resetForm();
    props.onSuccess();
  };

  return (
    <div className="my-2 px-4">
      <Formik initialValues={initialValues} onSubmit={submitToApi}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-2">
            <WaveStringField name="content" label="Content" />
            <FormButton title="Send Notification" disabled={isSubmitting} />
          </form>
        )}
      </Formik>
    </div>
  );
}

function EmailNotificationForm(props: NotificationFormProps) {
  const initialValues = {
    subject: '',
    content: '',
    user_id: props.userId,
  };

  const submitToApi = async (
    values: typeof initialValues,
    formik: FormikHelpers<any>,
  ) => {
    formik.setSubmitting(true);

    const [status] = await postResource(
      InternalToolsApiEndpoints.customNotifications.email,
      values,
    );

    formik.setSubmitting(false);

    if (status !== 201) {
      alert('Unable to send. Please try again or contact engineering.');
      return;
    }

    formik.resetForm();
    props.onSuccess();
  };

  return (
    <div className="my-2 px-4">
      <Formik initialValues={initialValues} onSubmit={submitToApi}>
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-2">
            <WaveStringField name="subject" label="Email Subject" />
            <WaveTextField
              name="content"
              label="Content"
              className="min-h-[200px]"
            />
            <FormButton title="Send Notification" disabled={isSubmitting} />
          </form>
        )}
      </Formik>
    </div>
  );
}

interface PastNotification {
  id: number;
  content: string;
  created_at: string;
  medium: string;
}

function PastNotifications({
  pastNotifications,
}: {
  pastNotifications?: PastNotification[];
}) {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className="border-t pt-2">
      <h2 className="text-xl font-bold">Past Outreach</h2>
      <ul className="space-y-3">
        {pastNotifications?.map((pastNotification) => (
          <li
            key={pastNotification.id}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            <div className="mb-4 text-gray-500 text-sm">
              <span className="font-bold">
                {capitalizeFirstLetter(pastNotification.medium)}{' '}
              </span>
              sent on:{' '}
              <HumanReadableDate isoValue={pastNotification.created_at} />
            </div>
            <div>{pastNotification.content}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
