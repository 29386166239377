import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SessionNote } from '../../../types/coaching/session_note';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import CoachingButton from '../coaching_button';
import SessionNoteForm from './session_notes/session_note_form';
import PastSessionNote from './session_notes/past_session_note';
import UserRecordSection from './user_record_section';

export default function SessionNotes() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , pastNotes, refetchPastNotes] = useFetchResource<any[]>(
    CoachingApiEndpoints.user_session_notes(userId),
  );

  const [showNewNoteModal, setShowNewNoteModal] = useState(false);
  const [editingNote, setEditingNote] = useState<SessionNote>();

  const startEdit = async (note: SessionNote) => {
    setEditingNote(note);
    setShowNewNoteModal(true);
  };

  const pastPublishedNotes = pastNotes?.filter((n) => !!n.published_on) || [];

  return (
    <UserRecordSection
      title="Session Notes"
      loading={loading}
      actionButton={
        <CoachingButton
          title="Add Session Note"
          onClick={() => {
            setShowNewNoteModal(true);
          }}
        />
      }>
      <>
        <ul className="space-y-3">
          {pastNotes?.map((item, i) => (
            <li
              key={item.id}
              className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
              <PastSessionNote
                note={item}
                sessionNumber={pastNotes.length - i}
                isExpandedByDefault={i === 0}
                onEdit={startEdit}
                onPublish={async () => {
                  await refetchPastNotes();
                }}
              />
            </li>
          ))}
        </ul>

        <SessionNoteForm
          show={showNewNoteModal}
          userId={userId!}
          existingRecord={editingNote}
          onClose={() => {
            setEditingNote(undefined);
            setShowNewNoteModal(false);
            refetchPastNotes();
          }}
          onSuccess={async () => {}}
          isIntakeSession={pastPublishedNotes.length === 0}
        />
      </>
    </UserRecordSection>
  );
}
