import { Appointment } from '../../../../types/coaching/appointment';
import { postResource } from '../../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../../api_endpoints';
import CoachingButton from '../../coaching_button';
import HumanReadableDate from '../../human_readable_date';

export default function AppointmentRecord({
  appointment,
  onAppointmentUpdated,
}: {
  appointment: Appointment;
  onAppointmentUpdated: () => Promise<void>;
}) {
  const updateAttendance = async (attended: boolean) => {
    let apiEndpoint: string;
    if (attended) {
      apiEndpoint = CoachingApiEndpoints.userAppointmentAttend(appointment.id);
    } else {
      apiEndpoint = CoachingApiEndpoints.userAppointmentNoShow(appointment.id);
    }
    const [status] = await postResource(apiEndpoint, {});

    if (status !== 200) {
      alert('Failed to update attendance. Please try again');
      return;
    }

    onAppointmentUpdated();
  };

  const undoAttendance = async () => {
    const [status] = await postResource(
      CoachingApiEndpoints.userAppointmentUndoAttendance(appointment.id),
      {},
    );

    if (status !== 200) {
      alert('Failed to undo attendance. Please try again');
    }

    onAppointmentUpdated();
  };

  const cancelAppointment = async () => {
    if (!window.confirm('Are you sure you want to cancel this appointment?')) {
      return;
    }

    const [status] = await postResource(
      CoachingApiEndpoints.userAppointmentCancel(appointment.id),
      {},
    );

    if (status !== 200) {
      alert('Failed to cancel appointment. Please try again');
      return;
    }

    onAppointmentUpdated();
  };

  const allowUndo = () => {
    if (appointment.user_attended === null) {
      return false;
    }

    const targetTime = new Date();
    targetTime.setMinutes(targetTime.getMinutes() - 30);
    return appointment.scheduled_for > targetTime.toISOString();
  };

  const allowCancel = () => {
    const targetTime = new Date();
    targetTime.setMinutes(targetTime.getMinutes() + 5);
    return new Date(appointment.scheduled_for) > targetTime;
  };

  const isFutureAppointment = () => {
    const targetTime = new Date();
    targetTime.setMinutes(targetTime.getMinutes() + 30);
    return new Date(appointment.scheduled_for) > targetTime;
  };

  return (
    <div>
      <div className="flex justify-between">
        <div
          className={
            'text-lg' +
            (appointment.user_attended !== false ? '' : ' text-gray-400')
          }>
          {appointment.user_attended !== null && !appointment.user_attended && (
            <span className="mr-2">No Show - </span>
          )}
          <HumanReadableDate isoValue={appointment.scheduled_for} />
        </div>

        <div className="flex space-x-4">
          {allowUndo() && (
            <div
              className="text-gray-500 underline cursor-pointer"
              onClick={undoAttendance}>
              Undo attendance check
            </div>
          )}
          {allowCancel() && (
            <div
              className="text-red-500 underline cursor-pointer"
              onClick={cancelAppointment}>
              Cancel appointment
            </div>
          )}
        </div>
      </div>

      {appointment.session_note && (
        <div>
          {!appointment.session_note.published_on && (
            <span className="font-bold text-red-600">
              Unpublished Session Note
            </span>
          )}
        </div>
      )}

      {!appointment.session_note &&
        appointment.user_attended === null &&
        !isFutureAppointment() && (
          <div>
            <div className="mb-4 pb-2 border-b border-gray-400">
              {appointment.daily_room_name && (
                <div className="mt-2 flex space-x-8">
                  <div className="underline text-blue-600">
                    <a
                      href={`/coaching/video-session?daily_room_name=${appointment.daily_room_name}&token=${appointment.daily_room_token}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      Join Session
                    </a>
                  </div>
                  <div
                    className="cursor-pointer underline"
                    onClick={() => {
                      navigator.clipboard.writeText(appointment.user_join_link);
                    }}>
                    Copy User Session Join Link
                  </div>
                </div>
              )}
            </div>

            <div className="flex space-x-4 mt-2">
              <CoachingButton
                title="User Attended"
                onClick={() => {
                  updateAttendance(true);
                }}
              />
              <CoachingButton
                title="User No Showed"
                onClick={() => {
                  updateAttendance(false);
                }}
                variant="primary"
              />
            </div>
          </div>
        )}
    </div>
  );
}
