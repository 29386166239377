import { useParams } from 'react-router-dom';
import ListCard from '../../../shared/components/list_card';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import HumanReadableDate from '../human_readable_date';
import UserRecordSection from './user_record_section';
import { useState } from 'react';

type DassAssessmnt = {
  id: number;
  created_at: string;

  question_1: number;
  question_2: number;
  question_3: number;
  question_4: number;
  question_5: number;
  question_6: number;
  question_7: number;
  question_8: number;
  question_9: number;
  question_10: number;
  question_11: number;
  question_12: number;
  question_13: number;
  question_14: number;
  question_15: number;
  question_16: number;
  question_17: number;
  question_18: number;
  question_19: number;
  question_20: number;
  question_21: number;

  what_brings_you_to_wave: string;
  what_feels_important: string;

  anxiety_score: number;
  anxiety_category: string;
  stress_score: number;
  stress_category: string;
  depression_score: number;
  depression_category: string;
};

const DASS_QUESTIONS = [
  'I found it hard to wind down',
  'I was aware of dryness of my mouth',
  'I couldn’t seem to experience any positive feeling at all',
  'I experienced breathing difficulty (e.g. excessively rapid breathing, breathlessness in the absence of physical exertion)',
  'I found it difficult to work up the initiative to do things ',
  'I tended to over-react to situations',
  'I experienced trembling (e.g. in the hands)',
  'I felt that I was using a lot of nervous energy',
  'I was worried about situations in which I might panic and make a fool of myself',
  'I felt that I had nothing to look forward to',
  'I found myself getting agitated',
  'I found it difficult to relax',
  'I felt down-hearted and blue',
  'I was intolerant of anything that kept me from getting on with what I was doing',
  'I felt I was close to panic',
  'I was unable to become enthusiastic about anything',
  'I felt I wasn’t worth much as a person',
  'I felt that I was rather touchy',
  'I was aware of the action of my heart in the absence of physical exertion (e.g. sense of heart rate increase, heart missing a beat)',
  'I felt scared without any good reason',
  'I felt that life was meaningless',
];

interface DassApiResponse {
  assessments: DassAssessmnt[];
  has_pending_assessment: boolean;
}

export default function DassAssessments() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;
  const [loading, , apiResponse] = useFetchResource<DassApiResponse>(
    CoachingApiEndpoints.usersDassAssessments(userId),
  );

  return (
    <UserRecordSection title="DASS Assessments" loading={loading}>
      {apiResponse?.has_pending_assessment && (
        <div className="bg-red-100 shadow-md overflow-hidden rounded-md px-6 py-4 mb-4">
          New DASS Assessment Pending
        </div>
      )}
      <ul className="space-y-3">
        {apiResponse?.assessments?.map((dass) => (
          <ListCard key={dass.id}>
            <DassRecord dass={dass} />
          </ListCard>
        ))}
      </ul>
    </UserRecordSection>
  );
}

function DassRecord({ dass }: { dass: DassAssessmnt }) {
  const [viewRaw, setViewRaw] = useState(false);

  return (
    <div>
      <div className="text-gray-400 mb-3">
        <HumanReadableDate isoValue={dass.created_at} />
      </div>
      <div className="space-y-4">
        <div className="border-b py-2">
          <div className="text-lg font-bold mb-2">Anxiety</div>
          <div className="space-y-2">
            <div>Score: {dass.anxiety_score}</div>
            <div>Category: {dass.anxiety_category}</div>
          </div>
        </div>

        <div className="border-b py-2">
          <div className="text-lg font-bold mb-2">Depression</div>
          <div className="space-y-2">
            <div>Score: {dass.depression_score}</div>
            <div>Category: {dass.depression_category}</div>
          </div>
        </div>

        <div className="border-b py-2">
          <div className="text-lg font-bold mb-2">Stress</div>
          <div className="space-y-2">
            <div>Score: {dass.stress_score}</div>
            <div>Category: {dass.stress_category}</div>
          </div>
        </div>
      </div>

      <div
        className="mt-4 text-blue-700 underline cursor-pointer"
        onClick={() => {
          setViewRaw(!viewRaw);
        }}>
        Toggle Raw Scores
      </div>

      {viewRaw && (
        <div className="mt-6">
          <div className="text-lg font-bold mb-4">Raw Scores</div>
          <div>
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="p-2">Question</th>
                  <th className="p-2">Score</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 21 }, (_, i) => i + 1).map((num) => (
                  <tr key={num} className="border-b border-gray-400">
                    <td className="p-2">{DASS_QUESTIONS[num - 1]}</td>
                    <td className="p-2">
                      {dass[`question_${num}` as keyof typeof dass]}
                    </td>
                  </tr>
                ))}
                <tr className="border-b border-gray-400">
                  <td className="p-2">What Brings you to Wave</td>
                  <td className="p-2">{dass.what_brings_you_to_wave}</td>
                </tr>
                <tr className="border-b border-gray-400">
                  <td className="p-2">What feels important</td>
                  <td className="p-2">{dass.what_feels_important}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
